export const DISTRIBUTION_STATUSES = [
    { value: 'new', label: 'новая', bgColor: 'none', allowEdit: true, textColor: '#000000' },
    { value: 'in_work', label: 'в работе', bgColor: '#B2D9FD', allowEdit: true, textColor: '#000000' },
    { value: 'sent', label: 'на проверке', bgColor: '#FFE6A5', allowEdit: false, textColor: '#000000' },
    { value: 'declined', label: 'отклонено', bgColor: '#FF6767', allowEdit: true, textColor: '#000000' },
    { value: 'accepted', label: 'принято', bgColor: '#A1E6C3', allowEdit: false, textColor: '#000000' },
    { value: 'approved', label: 'утверждено', bgColor: '#29612e', allowEdit: false, textColor: '#ffffff' },
    { value: 'deprecated', label: 'устаревшая', bgColor: '#dadde3', allowEdit: false, textColor: '#000000' },
    { value: 'blocked', label: 'заблокировано', bgColor: '#dadde3', allowEdit: false, textColor: '#000000' },
    { value: 'disabled', label: 'отключена', bgColor: '#dadde3', allowEdit: false, textColor: '#000000' },
]

export const DISTRIBUTION_VALUE_TYPES_NAMES = {
    int: { label: 'Целое число', regexp: /^\d+$/, convert: false },
    int_negative: { label: 'Отрицательное целое число', regexp: /^((-\d+)|(0+))$/, convert: false },
    float: { label: 'Дробное число', regexp: /^(0|[1-9]\d*)([.,]\d+)?$/, convert: true },
    float_negative: { label: 'Отрицательное дробное число', regexp: /^-(0|[1-9]\d*)([.,]\d+)?$/, convert: true },
    float_quarter: { label: 'Кратное 0.25', regexp: /^(-|\+)?\d+([.,](0|5|25|50|75))?$/, convert: true },
    float_round1: { label: '1 знак после плавающей точки', regexp: /^(-|\+)?\d+([.,]\d{1})?$/, fixed: 1, convert: true },
    float_round2: { label: '2 знака после плавающей точки', regexp: /^(-|\+)?\d+([.,]\d{2})?$/, fixed: 2, convert: true },
    any: { label: 'Произвольное значение', regexp: /^.{0,200}$/, convert: false }
}

export const XSL_TEMPLATE_ID = 'ae3770be-1773-5788-8b71-ae68197a7819';
export const XSLA_TEMPLATE_ID = '84ee6c74-7c24-568f-b94f-1e45f16d478e';
