<template>
    <ScrollPanel class="customStyles custom">
        <Listbox v-model="selectedComment" :options="comments" optionLabel="comments" dataKey="id" class="p-col-12 p-py-0 p-px-0 listBox">
            <template #option="slotProps">
                <div :class="[slotProps.index % 2 !== 0 ? 'greyBg' : '', 'commentContainer', 'p-d-flex', 'p-flex-column']">
                    <span>{{ slotProps.option.date | dateTime }}</span>
                    <span>{{ slotProps.option.name }}</span><br>
                    <span class="comment-message">{{ slotProps.option.message }}</span><br>
                    <div class="p-menubar-start" v-if="slotProps.option.deleted !== true">
                        <Button class="p-button comment-button p-mr-2"
                            type="button"
                            @click="showCommentDialog(slotProps.option)"
                            v-show="currentUser.id !== slotProps.option.userId && !isCommentAdmin"
                        >
                            <span class="p-button-label">Ответить</span>
                            <span class="p-ink"></span>
                        </Button>
                        <Button class="p-button comment-button p-mr-2"
                            type="button"
                            @click="showCommentDialog(slotProps.option, 'EDIT')"
                            v-show="currentUser.id === slotProps.option.userId || isCommentAdmin"
                        >
                            <span class="p-button-label">Редактировать</span>
                            <span class="p-ink"></span>
                        </Button>
                        <Button class="p-button p-button-danger comment-button"
                            type="button"
                            @click="deleteCommentDialog(slotProps.option.id)"
                            v-show="(currentUser.id === slotProps.option.userId) || isCommentAdmin"
                        >
                            <span class="p-button-label">Удалить</span>
                            <span class="p-ink"></span>
                        </Button>
                    </div>
               </div>
            </template>
        </Listbox>
    </ScrollPanel>
</template>

<script>
import ScrollPanel from 'primevue/scrollpanel';
import { USER_PERMISSIONS_MAP } from "@/constants/common";
import { deleteComment } from '@/api/form/formsDistributions';
import { mapGetters } from 'vuex';

const {
    commentAdmin
} = USER_PERMISSIONS_MAP

export default {
    name: 'comments',
    props: {
        distributionId: {
            require: true,
            type: String,
        },
        worksheetId: {
            require: true,
            type: String,
        },
        worksheetComments: {
            require: true,
            type: Array,
        },
    },
    emits: ['showCommentDialog'],
    data() {
        return {
            commentText: '',
            comments: this.worksheetComments,
            currentComment: null,
            selectedComment: null,
            isCommentAdmin: false,
        };
    },
    components: {
        ScrollPanel,
    },
    methods: {
        showCommentDialog(comment, mode = 'CREATE') {
            this.$emit('showCommentDialog', comment.id, mode, comment.message);
        },

        deleteCommentDialog(commentId) {
            this.currentComment = commentId;
            this.selectedComment = this.comments.find(comment => comment.id === commentId);

            if (!this.selectedComment.deleted) {
                this.$root.$emit('showAcceptDeleteDialog', {
                    acceptAction: this.removeComment
                })
            }
        },

        async removeComment() {
            const result = await deleteComment(this.currentComment);

            if (this.selectedComment) {
                this.selectedComment.message = result.attributes.message;
                this.selectedComment.deleted = true;
            } else {
                this.comments[0].message = result.attributes.message;
                this.comments[0].deleted = true;
            }
        },
    },
    computed: {
        ...mapGetters('auth', [
            'currentUser',
            'userPermissionsObject'
        ]),
    },
    watch: {
        worksheetComments: {
            handler(to) {
                if (to) {
                    this.comments = this.worksheetComments;
                }
            }
        },
    },
    mounted() {
        this.isCommentAdmin = [ commentAdmin ].some(p => this.userPermissionsObject[p]);
    }
};
</script>

<style lang="scss" scoped>
.customStyles {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
}

::v-deep .listBox {
    border: none;
    border-bottom: 1px solid #eaeaea;
    .p-listbox-list {
        padding-top: 0;
        padding-bottom: 0;
        li {
            padding: 0;
            .commentContainer {
                padding: 8px 12px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
                color: #272727;
            }
            .boldText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 125%;
            }
        }
    }
}
::v-deep .p-scrollpanel {
    &.custom {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-b);
            border-bottom: 9px solid var(--surface-b);
        }

        .p-scrollpanel-bar {
            background-color: red;
            border-radius: 0;
            opacity: 1;
            transition: background-color 0.2s;
        }
        .p-scrollpanel-bar-y {
            width: 6px;
        }
    }
}
.commentContainer {
    align-items: start;
    background: #fff;
}
.greyBg {
    background: #f5f5f5;
}
.comment-message {
    font-size: 14px;
}
.comment-button {
    z-index: 1;
}
</style>
