export const searchFirstRoute = (routes, userPermissionsObject) => {
    let result
    for (const route of routes) {
        const { permissionAccess, isMain } = route.meta || {}
        if (isMain && ((!permissionAccess || !permissionAccess.length) || permissionAccess.some(p => userPermissionsObject[p]))) {
            return route
        }
        if (route.children && route.children.length) {
            result = searchFirstRoute(route.children, userPermissionsObject)
            if (result) return result
        }
    }
    return result
}

export const onlyCompletedFields = obj => {
    Object.keys(obj).forEach(prop => {
        if (obj[prop] === undefined || obj[prop] === null) delete obj[prop]
    })
    return obj
}

export function createElementsFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return [...div.children];
}

export function incrementFormulaElements({
                                             htmlString,
                                             rowOffset,
                                             colOffset,
                                             maxRowNumber,
                                             maxColNumber,
                                             worksheetTitle,
                                             dataFromTable,
                                             hot,
                                             coordinates,
                                             worksheetId,
                                             cellData
                                         }) {
    let errors = [];
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    div.childNodes?.forEach(i => {
        if (i.attributes) {
            // проверка на ячейку из другой формы/листа
            const sharedId = i.attributes['data-sharedid'].value;
            let sharedCell = cellData.formTemplateCells.find((cell) => cell.attributes.sharedId === sharedId);
            let sharedCellWorksheet, sharedCellTemplate, sharedCellForm;
            if (sharedCell) {
                sharedCellWorksheet = cellData.formTemplateWorksheets.find((worksheet) => worksheet.id === sharedCell.relationships.worksheet.data.id);
                sharedCellTemplate = cellData.formTemplates.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
                sharedCellForm = cellData.forms.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);
                let sharedRow = (sharedCell.attributes.row - 1) + rowOffset;
                let sharedColumn = (sharedCell.attributes.column - 1) + colOffset;
                if (!sharedCellWorksheet.attributes.properties?.rowLabels[sharedRow]
                    || !sharedCellWorksheet.attributes.properties?.columnLabels[sharedColumn]) {
                    errors.push(`Ячейка при протяжке выходит за пределы листа: ${ sharedCellForm.attributes.name }(${ sharedCellTemplate.attributes.name }): '${ sharedCellWorksheet.attributes.name }'. Проверьте формулу.`);
                }
            }
            const elemDataRow = Number(i.attributes['data-row'].value)
            const elemDataCol = Number(i.attributes['data-col'].value)
            const resultRowVal = elemDataRow + rowOffset
            const resultColVal = elemDataCol + colOffset
            const row = getRowOrColValue(resultRowVal, maxRowNumber - 1);
            const column = getRowOrColValue(resultColVal, maxColNumber - 1);
            const targetSharedId = dataFromTable[row][column].attributes.sharedId;
            const newElHtml = createCellLayout({
                shareId: dataFromTable[row][column].attributes.sharedId,
                row: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? (sharedCell.attributes.row - 1) + rowOffset : row,
                column: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? (sharedCell.attributes.column - 1)  + colOffset : column,
                worksheetTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellWorksheet?.attributes.name : worksheetTitle,
                templateTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellTemplate?.attributes.name : null,
                formTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellForm?.attributes.name : null,
                hot,
                coordinates: sharedCellWorksheet?.id ? {
                    rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                    columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                } : coordinates
            })
            const rootForNewEl = document.createElement('div')
            rootForNewEl.innerHTML = newElHtml.trim()
            const newEl = rootForNewEl.firstChild
            i.setAttribute('data-sharedid', targetSharedId)
            i.setAttribute('data-row', getRowOrColValue(resultRowVal, maxRowNumber - 1))
            i.setAttribute('data-col', getRowOrColValue(resultColVal, maxColNumber - 1))
            i.innerHTML = newEl.innerHTML
        }
    })
    return { html: div.innerHTML, errors };
}

export function createCellLayout({
                                     sharedId,
                                     row,
                                     column,
                                     worksheetTitle,
                                     templateTitle = null,
                                     formTitle = null,
                                     periodId = null,
                                     hot,
                                     cellWithOterForm,
                                     prevPeriodMode,
                                     accumulateMode,
                                     coordinates = { rowLabels: [], columnLabels: [] }
                                 }) {
    const periodInfo = periodId ? `data-periodid=${periodId}` : '';
    const prevPeriodInfo = prevPeriodMode ? `data-prevperiod=${prevPeriodMode}` : '';
    const accumulateInfo = accumulateMode ? `data-accumulate=${accumulateMode}` : '';
    const cellWithOterFormInfo = cellWithOterForm ? `data-cellWithOterForm=${cellWithOterForm}` : '';
    let columnName, rowName;
    if (coordinates.columnLabels && coordinates.columnLabels[column] && coordinates.rowLabels[row]) {
        columnName = `грф.${ coordinates.columnLabels[column] }:`;
        rowName = `стр.${ coordinates.rowLabels[row] }`;
    } else {
        columnName = hot?.getColHeader(column);
        rowName = row + 1;
    }

    let resultStr = '';

    if (templateTitle && formTitle) {
        resultStr = `<div contenteditable="false" class="cellMarker ${prevPeriodMode ? 'prev_period' : ''} ${accumulateMode ? 'prev_period' : ''}"  data-sharedid="${sharedId}" ${periodId ? periodInfo : ''}  ${prevPeriodMode ? prevPeriodInfo : ''} ${accumulateMode ? accumulateInfo : ''} ${
            cellWithOterForm ? cellWithOterFormInfo : ''
        } data-row="${row}" data-col="${column}">${formTitle} (${templateTitle}): '${worksheetTitle}' !${columnName}${rowName}</div>`
    } else {
        resultStr = `<div contenteditable="false" class="cellMarker ${prevPeriodMode ? 'prev_period' : ''} ${accumulateMode ? 'prev_period' : ''}" data-sharedid="${sharedId}" ${prevPeriodMode ? prevPeriodInfo : ''} ${accumulateMode ? accumulateInfo : ''}
            data-row="${row}" data-col="${column}">'${worksheetTitle}' !${columnName}${rowName}</div>`;
    }

    return resultStr;
}

export function getRowOrColValue(value, maxValue) {
    if (value >= 0) {
        if (value > maxValue) return maxValue
        return value
    }
    return 0
}

export const pushOrUpdate = (arr, item, prop = 'id') => {
    const idx = arr.findIndex(i => i[prop] === item[prop])
    ~idx ? arr[idx] = item : arr.push(item)
}

export const blockDblClick = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    return false
}

export const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data))
}

export function convertLayer({ layer, included }) {
    let nodeElement = '';
    let arrayElements = layer.split('\')').map((element) => {
        if (element.includes('getByTag(')) {
            nodeElement = element.concat('', '\')');
        } else if (element.includes('in_array(')) {
            nodeElement = element;
        } else {
            element.includes('(\'') ? (nodeElement = element + '\')') : (nodeElement = element);
        }

        return nodeElement.trim();
    });

    let resultArr = [];
    arrayElements.forEach((string) => {
        let isFormula = false;
        ['getByTag', 'cell', 'period', 'accumulate'].forEach((type) => {
            if (string.indexOf(type) >= 0) {
                if (type === 'period' && string.indexOf('prev_period') < 0) {
                    if (string.indexOf('count_periods') >= 0 || string.indexOf('current_period') >= 0) {
                        isFormula = true;
                        return resultArr.push(string);
                    }

                    string.split(' ').forEach((substring) => {
                        if (substring.trim().length === 0) {
                            return;
                        }
                        if (substring.indexOf('period') < 0) {
                            return resultArr.push(substring);
                        }
                        if (substring.indexOf('period') >= 0) {
                            let formulaArray = string.split(new RegExp(`${ type }\\('|'\\)`));
                            let newItem = formulaArray.map((item) => {
                                if (item.length === 36) {
                                    isFormula = true;
                                    return getFormula(
                                        type === 'cell' ? '' : `${ type }(`,
                                        item,
                                        type === 'cell' ? '' : ')',
                                        included
                                    );
                                }

                                return item;
                            }).join(' ');

                            resultArr.push(newItem);
                        }
                    });
                    return;
                } else {
                    if (type === 'period') {
                        type = 'prev_period';
                    }

                    let formulaArray = string.split(new RegExp(`${ type }\\('|'\\)`));
                    let newItem = formulaArray.map((item) => {
                        if (item.indexOf(',') > 0) {
                            if (type === 'getByTag') {
                                const [cellSharedId, tagString] = item.split('\',\'');

                                if (cellSharedId.length === 36) {
                                    isFormula = true;
                                    return getFormula(`${type}(`, cellSharedId, `, ${tagString})`, included);
                                }
                            }
                        }
                        if (item.length === 36) {
                            isFormula = true;
                            return getFormula(
                                type === 'cell' ? '' : `${type}(`,
                                item,
                                type === 'cell' ? '' : ')',
                                included
                            );
                        }

                        return item;
                    }).join(' ');

                    return resultArr.push(newItem);
                }
            }
        });

        if (!isFormula) {
            resultArr.push(string);
        }
    });

    return resultArr.join(' ');
}

export function getFormula(formula = '', sharedId, params = '', included) {
    if (formula === 'period(') {
        let period = included.find((item) => item.id === sharedId);

        if (period) {
            return `period(${ period && period.attributes.name })`;
        }
    }
    let sharedCell = included.find((cell) => cell.attributes.sharedId === sharedId);
    if (!sharedCell) {
        sharedCell = included.find((cell) => cell.attributes.sharedId === sharedId);
    }

    if (!sharedCell) {
        return `${ formula }${ sharedId }${ params }`;
    } else {
        const {
            row,
            column,
            worksheetTitle,
            templateTitle,
            formTitle,
            coordinates
        } = getCellData(sharedCell, included);

        return `${ formula }${ getCellFormat({
            row: row,
            column: column,
            worksheetTitle,
            templateTitle,
            formTitle,
            coordinates
        }) }${ params }`;
    }
}

export function getCellFormat({
         row,
         column,
         worksheetTitle,
         templateTitle = null,
         formTitle = null,
         coordinates
     }) {
    let columnName = column;
    let rowName = row;
    if (coordinates) {
        if (coordinates?.columnLabel && coordinates?.rowLabel) {
            columnName = `грф.${coordinates.columnLabel}:`;
            rowName = `стр.${coordinates.rowLabel}`
        } else {
            columnName = coordinates?.meta[0];
            rowName = row;
        }
    }

    let resultStr = ''

    if (templateTitle && formTitle) {
        resultStr = `${formTitle} (${templateTitle}): '${worksheetTitle}' !${columnName}${rowName}`
    } else {
        resultStr = `'${worksheetTitle}' !${columnName}${rowName}`;
    }

    return resultStr;
}

export function getCellData(sharedCell, included) {
    if (!sharedCell) {
        return;
    }

    const sharedCellWorksheet = included.find((worksheet) => worksheet.id === sharedCell.relationships.worksheet.data.id);
    const sharedCellTemplate = included.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
    const sharedCellForm = included.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

    return {
        row: sharedCell.attributes.row,
        column: sharedCell.attributes.column,
        worksheetTitle: sharedCellWorksheet.attributes.name,
        templateTitle: sharedCellTemplate?.attributes.name,
        formTitle: sharedCellForm?.attributes.name,
        coordinates: {
            columnLabel: (sharedCellWorksheet.attributes.properties?.columnLabels
                ? sharedCellWorksheet.attributes.properties?.columnLabels[sharedCell.attributes.column - 1] : null),
            rowLabel: (sharedCellWorksheet.attributes.properties?.rowLabels
                ? sharedCellWorksheet.attributes.properties?.rowLabels[sharedCell.attributes.row - 1] : null),
            meta: sharedCell.meta.coordinate
        }
    };
}

export function generateUniqueString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let string = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        string += characters[randomIndex];
    }

    return string;
}
