<template>
    <div id="printList">
        <Card v-show="status.length && status !== 'finish' && status !== 'clear'">
            <template #content>
                <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-ai-center status">
                        <span class="status-label calculated">Выполняется проверка формы</span>
                    </div>
                </div>
            </template>
        </Card>
        <Card v-if="!status.length && !errorsSet.length">
            <template #content>
                <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column">
                        <span class="status-label" style="background: rgb(255, 103, 103); padding: 6px; border-radius: 4px;">Требуется выполнить проверку формы</span>
                    </div>
                </div>
            </template>
        </Card>
        <Card v-if="status === 'clear'">
            <template #content>
                <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column">
                        <span class="status-label" style="background: rgb(255, 103, 103); padding: 6px; border-radius: 4px;">
                            Данные обновились<br/>
                            Требуется проверка формы
                        </span>
                    </div>
                </div>
            </template>
        </Card>
        <Accordion :multiple="true" :activeIndex="active" ref="accordion2" v-if="errorsSet.length > 0">
            <AccordionTab v-for="group in errorsSet" :key="group.id" :id="group.id"
                          v-bind:class="{ nulableHeight: group.id === 'noId' }">
                <template #header>
                    <div class="p-d-flex p-flex-column" style="width: 100%;">
                        {{ group.header }}
                    </div>
                </template>
                <div v-for="(error, index) in group.errors" :key="error.sort"
                     :class="[index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column" style="width: 100%;">
                        <span class="error-description" v-html="error.cell"></span>
                        <span>{{ error.message }}</span>
                        <span class="error-description" v-html="error.formula"></span>
                        <span class="error-expain">{{ error.explain }}</span>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <Card v-if="0 === errorsSet?.length && (status === 'finish' || status === 'from_cache')">
            <template #content>
                <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column">
                        <span>Ошибок не обнаружено</span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>

export default {
    name: 'formMistakes',

    props: {
        errorGroups: {
            require: true,
            type: Array
        },
        errorFormStatus: {
            type: String
        },
        formTitle: {
            require: true,
            type: String
        },
        worksheetTitle: {
            require: true,
            type: String
        }
    },

    emits: [ 'showErrorCell' ],

    data() {
        return {
            status: this.errorFormStatus,
            errorsSet: this.errorGroups,
        }
    },

    methods: {
        showErrorCell(event) {
            this.$emit('showErrorCell', {
                row: +event.target.dataset.row,
                column: +event.target.dataset.column
            });
        },

        updateErrors() {
            this.errorsSet = this.errorGroups;
            this.status = this.errorFormStatus;
        },

        printErrors() {
            const prtHtml = document.getElementById('printList').innerHTML;
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');

            WinPrint.document.write(`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .p-flex-column {
                flex-direction: column !important;
            }
            .p-d-flex {
                display: flex !important;
            }
            .customStyles {
                width: 100%;
                max-height: 100%;
                overflow: auto;
                z-index: 10;
            }

            .userContainer {
                align-items: start;
                background: #fff;
                padding: 1rem;
            }

            .greyBg {
                background: #f5f5f5;
            }

            .error-description {
                font-weight: 500;
                margin: 4px 0;
            }

            .p-card {
                box-shadow: none;
            }

            .p-card .p-card-body {
                border-radius: 0;
                padding: 0 !important;
                border: none;
            }

            .p-card .p-card-content {
                padding: 0 !important;
                font-size: 13px;
            }

            .cell-marker {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                border-radius: 3px;
                cursor: pointer;
                display: inline-block !important;
                background: #FF8F8F;
                padding: 2px;
                margin: 1px;
            }

            .green .cell-marker {
                background: #C5E0C8;
            }

            .error-expain {
                color: #F44336;
            }

            .printHeader {
                display: none;
            }

           ::v-deep .p-toggleable-content {
                display: block !important;
            }
            @media print {
                ::v-deep .p-toggleable-content {
                    display: block !important;
                }

                .printHeader {
                    display: block;
                }
            }
        </style>
    </head>
    <body>
        ${prtHtml}
    </body>
</html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        }
    },

    watch: {
        errorFormStatus: {
            handler(to) {
                if (to) {
                    this.status = this.errorFormStatus;
                }
            }
        },
        errorGroups: {
            handler(to) {
                if (to) {
                    this.updateErrors();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customStyles {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
}

.status {
    color: #8794a3;
    white-space: nowrap;

    .status-label {
        background: #ffe6a5;
        padding: 6px;
        border-radius: 4px;
        color: #495058;
    }
}

::v-deep .p-scrollpanel {

    &.custom {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-b);
            border-bottom: 9px solid var(--surface-b);
        }

        .p-scrollpanel-bar {
            background-color: red;
            border-radius: 0;
            opacity: 1;
            transition: background-color 0.2s;
        }

        .p-scrollpanel-bar-y {
            width: 6px;
        }

        .p-button {
            margin-top: 4px;
        }
    }
}

.userContainer {
    align-items: start;
    background: #fff;
    padding: 1rem;
    justify-content: center;
}

.greyBg {
    background: #f5f5f5;
}

.error-description {
    font-weight: 500;
    margin: 4px 0;
}

::v-deep .p-card {
    box-shadow: none;

    & .p-card-body {
        border-radius: 0;
        padding: 0 !important;
        border: none;
    }

    & .p-card-content {
        padding: 0 !important;
        font-size: 13px;
    }
}

::v-deep .cell-marker {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #C5E0C8;
    padding: 2px;
    margin: 1px;
}

.error-expain {
    color: #F44336;
}

::v-deep .nulableHeight {

    .p-accordion-header {
        display: none;
    }
}

::v-deep .p-accordion .p-accordion-content {
    padding: 0;
}

.printHeader {
    display: none;
}

@media print {
    .printHeader {
        display: block;
    }
}
</style>
